import PropTypes from 'prop-types';
import filesize from 'filesize';
import { getNativeFileUrl } from 'media/utils';
import { getUrl, getApiUrl } from 'utils/urls';
import { iconNames, mediaTypes } from 'media/constants/fileTypes';
import { isNativeAppWebview } from 'utils/general';

export default class FileModel {
  constructor({
    id,
    timestamp,
    name,
    mediaType,
    type,
    size,
    keccak256,
    isEditable,
    thumbnail,
    preview = '',
    path = '',
  }) {
    this.id = id;
    this.timestamp = timestamp || null;
    this.name = name;
    this.mediaType = mediaType || type;
    this.size = size;
    this.keccak256 = keccak256;
    this.thumbnail = thumbnail || '';
    this.isEditable = isEditable || false;
    this.path = path;
    this.preview = preview;
  }

  static propType = PropTypes.shape({
    id: PropTypes.number,
    timestamp: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    mediaType: PropTypes.string,
    size: PropTypes.number,
    keccak256: PropTypes.string,
    thumbnail: PropTypes.string,
    isEditable: PropTypes.bool,
    path: PropTypes.string,
    preview: PropTypes.string,
  });

  update(data) { Object.entries(data).forEach(([key, value]) => { this[key] = value; }); }

  get isImage() { return this.mediaType.startsWith('image/'); }

  get isPdf() { return this.mediaType === 'application/pdf'; }

  get fetchUrl() { return getUrl(`/documents/fetch/${this.id}/`); }

  // @todo currently not used but could become useful again once we revisit the `fetchUrl` behaviour
  get nativeUrl() {
    return this.makeNativeUrl(this.fetchUrl);
  }

  makeNativeUrl = (url) => (
    getNativeFileUrl(url, this.name, this.mediaType, this.timestamp, this.keccak256)
  );

  get previewSource() { return this.fetchUrl || this.preview; }

  get iconName() {
    const iconName = iconNames.find((option) => option.id === this.mediaType);
    return iconName ? iconName.text : '';
  }

  get mediaTypeDisplayName() {
    const mediaTypeName = mediaTypes.find((option) => option.id === this.mediaType);
    return mediaTypeName ? mediaTypeName.text : '';
  }

  get readableFileSize() { return filesize(this.size); }

  static fromNativeFile(obj) { return obj instanceof this ? obj : new this(obj); }

  download({ urlBase = undefined, downloadUrl }) {
    const url = urlBase ? `${urlBase}files/${this.id}/download/` : downloadUrl;
    window.location.href = isNativeAppWebview ? this.nativeUrl : getApiUrl(url);
  }
}
